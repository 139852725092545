const homeLcOptions = [
    { id: 4567, full_name: '-' },
    { id: 608, full_name: 'Ahmedabad' },
    { id: 1490, full_name: 'Bengaluru' },
    { id: 2872, full_name: 'Bhopal' },
    { id: 1669, full_name: 'Bhubaneswar' },
    { id: 1449, full_name: 'Chandigarh' },
    { id: 630, full_name: 'Chennai' },
    { id: 21, full_name: 'Dehradun' },
    { id: 651, full_name: 'Delhi IIT' },
    { id: 588, full_name: 'Delhi University' },
    { id: 632, full_name: 'Hyderabad' },
    { id: 2340, full_name: 'India National Office' },
    { id: 272, full_name: 'Indore' },
    { id: 616, full_name: 'JAIPUR' },
    { id: 241, full_name: 'Jalandhar' },
    { id: 14, full_name: 'Jodhpur' },
    { id: 631, full_name: 'Kolkata' },
    { id: 5787, full_name: 'Lucknow' },
    { id: 1794, full_name: 'Ludhiana' },
    { id: 273, full_name: 'M.A.H.E.' },
    { id: 1393, full_name: 'Mumbai' },
    { id: 2289, full_name: 'Nagpur' },
    { id: 150, full_name: 'Navi Mumbai' },
    { id: 2873, full_name: 'Noida' },
    { id: 74, full_name: 'Patiala' },
    { id: 1418, full_name: 'Pune' },
    { id: 229, full_name: 'Surat' },
    { id: 75, full_name: 'Visakhapatnam' },
    { id: 2012, full_name: 'VIT' },
  ];
  
  export default homeLcOptions;